@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800;900&display=swap');

@layer base {
    body {
        @apply font-[Raleway];
        @apply dark:bg-[#0a192f];
    }
    li {
        @apply px-4;
        @apply cursor-pointer
    }
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
}

a {
  text-decoration: none;
  color: inherit;
  background-color: transparent;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}

.btn {
  border: 2px solid rgb(57, 134, 250);
  padding: 10px 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-weight: 400;
  outline: none;
}

.PrimaryBtn {
  background: rgb(57, 134, 250);
  color: #f6f6f6;
}

.PrimaryBtn:hover {
  background: #fff;
  color: #010606;
}

.SecondarBtn:hover {
  background: rgb(57, 134, 250);
  color: #fff;
}

.btn2 {
  margin-right: 1rem;
}

.Container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.Title{
  font-size: 2.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  text-align: center;
  padding-top: 2rem;
}

.AboutBio,
.tagline2 {
  text-align: center;
  max-width: 650px;
  margin: 0 auto;
  color: #151418;
  line-height: 1.6;
  margin-top: 15px;
}

.tagline2 {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.SectionTitle {
  font-size: 28px;
  color: #151418;
  font-weight: 700;
  margin-bottom: 3.5rem;
}
.ProjectWrapper {
  margin-top: -1rem;
}

.footer {
  text-align: center;
}

.footer__link {
  font-size: 0.9rem;
  font-weight: 600;
}

@media (max-width: 600px) {
  .footer {
    padding: 2em;
    margin-top: 3em;
  }
}

/* ------------------------------------ Media for Container */
@media (min-width: 576px) {
  .Container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .Container {
    max-width: 720px;
  }
  .ProjectWrapper {
    margin-top: -5rem;
  }
}
@media (min-width: 992px) {
  .Container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .Container {
    max-width: 1000px;
  }
}
